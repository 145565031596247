/* CSS for expand/collapse animation */
.expand-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.expand-enter-active {
    max-height: 200px;
    /* Set a high enough value for your content */
    opacity: 1;
}

.expand-exit {
    max-height: 200px;
    /* Same high value as in enter-active */
    opacity: 1;
    overflow: hidden;
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

.expand-exit-active {
    max-height: 0;
    opacity: 0;
}

.slide-full-enter {
    transform: translateY(100%);
}

.slide-full-enter-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}

.slide-full-exit {
    transform: translateY(0);
}

.slide-full-exit-active {
    transform: translateY(100%);
    /* This changes the exit to slide down */
    transition: transform 300ms ease-in-out;
}

/* CSS for the slide-up and slide-down animation */
.slide-full-enter {
    transform: translateY(100%);
}

.slide-full-enter-active {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
}

.slide-full-exit {
    transform: translateY(0);
}

.slide-full-exit-active {
    transform: translateY(100%);
    transition: transform 300ms ease-in-out;
}